import {ILoans} from '@common/interface';
import {createSlice} from '@reduxjs/toolkit';

interface FixedLoanOffers {
  id: number;
  tenureInWeeks: number | string;
  weeklyRepaymentAmount: number | string;
  eligibleToShow: boolean;
  roundedDsr: number | string;
  estTenureInWeeks: number | string;
  estWeeklyRepaymentAmount: number | string;
  grossLoanAmount: number | string;
  adjustedGrossLoanAmount: number | string;
  feeDiscount: number | string;
  disbursementAmount?: number | string;
}

interface ILoanOfferData {
  seedflexMId: string;
  loanApplicationId: string;
  loanOfferRequestId: string;
  tenureCardId: string;
  currency: string;
  currencySymbol: string;
  loanAmount: number;
  loanType: string;
  grossLoanAmount: number;
  feeAmount: number;
  feePercent: number;
  offers: FixedLoanOffers[];
  partnerDetails: {
    id: string | number;
    name: string;
  };
  disbursementAmount?: number;
}

export interface IConfigData {
  seedflexMId: string;
  currency: string;
  currencySymbol: string;
  sliderValueMultiplier: number;
  stampDutyPercentage: number;
  responses: ILoans[];
}

interface ISelectedLender {
  id: number;
  name: string;
  logo: string;
  type: string;
  description1: string;
  description2: string;
  lenderCharges: number;
  countryCode: string;
  status: string;
  platformFee: number;
}

interface Step2FormSubmitResponse {
  id: number;
  selectedLoanAmount: number;
  feePercent: number;
  feeAmount: number;
  weeklyRepaymentAmount: number;
  dsr: number;
  tenureInWeeks: number;
  grossLoanAmount: number;
  deductionsThrough: Date | null; // Adjust the type based on the actual type in your data
  firstRepaymentDate: string;
  lastRepaymentDate: string;
  repaymentPartnerDetails: {
    id: number;
    name: string;
  };
}

interface IStep3FormSubmitResponse {
  countryMobileCode: string;
  phoneNumber1: string;
  phoneNumber2: string;
}

interface ILoanApplicationInitial {
  activeStep: number;
  isLoanApplicationStarted: boolean;
  isLoanApplicationCompleted: boolean;
  enabledSteps: {
    step_1: boolean;
    step_2: boolean;
    step_3: boolean;
    step_4: boolean;
    step_5: boolean;
    step_6: boolean;
    step_7: boolean;
    step_8: boolean;
  };
  loanApplicationConfig: IConfigData;
  loanOfferData: ILoanOfferData | null;
  lenderData: ISelectedLender[] | null;
  selectedLender: ISelectedLender | null;
  step2FormSubmitResponse: Step2FormSubmitResponse | null;
  step3FormSubmitResponse: IStep3FormSubmitResponse | null;
  invalidOtp: string;
  totalLimit: number;
  loanApplicationId: string | number;
  isResumeApplication: boolean;
  isUpdateLoanOffer: boolean;
  partnerName: string;
  repaymentPartnerName: string;
  isBankAccConnectInProgress: boolean;
  hasbankLinkError: boolean;
  businessName: string;
}

const initialState: ILoanApplicationInitial = {
  activeStep: 1,
  isLoanApplicationStarted: false,
  isLoanApplicationCompleted: false,
  enabledSteps: {
    step_1: false,
    step_2: false,
    step_3: true,
    step_4: false,
    step_5: false,
    step_6: true,
    step_7: false,
    step_8: false,
  },
  loanApplicationConfig: {
    seedflexMId: '',
    currency: '',
    currencySymbol: '',
    sliderValueMultiplier: 0,
    stampDutyPercentage: 0,
    responses: [
      {
        loanType: '',
        feePercent: 0,
        minLimit: 0,
      },
    ],
  },
  loanOfferData: null,
  lenderData: null,
  selectedLender: null,
  step2FormSubmitResponse: null,
  step3FormSubmitResponse: null,
  invalidOtp: '',
  totalLimit: 0,
  loanApplicationId: '',
  isResumeApplication: false,
  isUpdateLoanOffer: true,
  partnerName: '',
  repaymentPartnerName: '',
  isBankAccConnectInProgress: false,
  hasbankLinkError: false,
  businessName: '',
};

const login = createSlice({
  name: 'loanApplication',
  initialState,
  reducers: {
    setActiveStep(state, actions) {
      state.activeStep = actions.payload;
    },
    setLoantotalLimit(state, actions) {
      state.totalLimit = actions.payload;
    },
    setLoanApplicationId(state, actions) {
      state.loanApplicationId = actions.payload;
    },
    setIsResumeApplication(state, actions) {
      state.isResumeApplication = actions.payload;
    },
    setIsLoanApplicationStarted(state, actions) {
      state.isLoanApplicationStarted = actions.payload;
    },
    setIsLoanApplicationCompleted(state, actions) {
      state.isLoanApplicationCompleted = actions.payload;
    },
    setEnabledSteps(state, actions) {
      state.enabledSteps = actions.payload;
    },
    setLoanConfigData(state, actions) {
      state.loanApplicationConfig = actions.payload;
    },
    setLoanOfferData(state, actions) {
      state.loanOfferData = actions.payload;
    },
    setLenderData(state, actions) {
      state.lenderData = actions.payload;
    },
    setSelectedLender(state, actions) {
      state.selectedLender = actions.payload;
    },
    setStep2SubmitResponse(state, actions) {
      state.step2FormSubmitResponse = actions.payload;
    },
    setStep3SubmitResponse(state, actions) {
      state.step3FormSubmitResponse = actions.payload;
    },
    setInvalidOtpMessage(state, actions) {
      state.invalidOtp = actions.payload;
    },
    setIsUpdateLoanOffer(state, actions) {
      state.isUpdateLoanOffer = actions.payload;
    },
    setPartnerName(state, action) {
      state.partnerName = action.payload;
    },
    setRepaymentPartnerName(state, action) {
      state.repaymentPartnerName = action.payload;
    },
    setIsBankAccConnectInProgress(state, actions) {
      state.isBankAccConnectInProgress = actions.payload;
    },
    setHasbankLinkError(state, actions) {
      state.hasbankLinkError = actions.payload;
    },
    setBusinessName(state, action) {
      state.businessName = action.payload;
    },
  },
});

const loanApplicationReducer = login.reducer;

export const loanApplicationActions = login.actions;

export default loanApplicationReducer;
